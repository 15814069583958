import moment from 'moment';
export default {
    log: function (...arg) {
        console.log("%c控制台日志输出：", "color:#fff;font-size:14px;font-weight:bold;background-color:#409eff;border-radius: 4px;padding:0px 8px;", ...arg);
    },
    err: function (...arg) {
        console.error("=============", ...arg);
    },
    save: function (name, data) {
        localStorage.setItem(name, JSON.stringify(data));
    },
    del: function (name) {
        localStorage.removeItem(name);
    },
    get: function (name) {
        var s = localStorage.getItem(name);
        if (s) {
            return JSON.parse(s);
        }
        return;
    },
    saveS: function (name, data) {
        localStorage.setItem(name, data);
    },
    getS: function (name) {
        return localStorage.getItem(name);
    },
    ssave: function (name, data) {
        sessionStorage.setItem(name, JSON.stringify(data));
    },
    sdel: function (name) {
        sessionStorage.removeItem(name);
    },
    sget: function (name) {
        var s = sessionStorage.getItem(name);
        if (s) {
            return JSON.parse(s);
        }
        return;
    },
    ssaveS: function (name, data) {
        sessionStorage.setItem(name, data);
    },
    sgetS: function (name) {
        return sessionStorage.getItem(name);
    },
    checkMobile: function (sMobile) {
        if (!(/^1[3|4|5|8|7|9|6][0-9]\d{4,8}$/.test(sMobile))) {
            return false;
        }
        return true;
    },
    copy: function (obj) {
        return JSON.parse(JSON.stringify(obj));
    },
    format: function (t, fmt) {
        var fm = "YYYY-MM-DD HH:mm:ss";
        if (fmt) {
            fm = fmt;
        }
        return moment(t * 1000).format(fm);
    },
    firstUpper: function (char) {
        return char.charAt(0).toUpperCase() + char.slice(1);
    },
    getAmount: function (fee, showUnit) {
        var u = this.get("user-info");
        var f = 0;
        var unit = "";
        if (u && u.show_currency == 1) {
            f = fee;
            unit = " 金币";
        } else {
            f = (fee * u.rate).toFixed(2);
            unit = " 元";
        }
        if (showUnit) {
            return f + unit
        }
        return f
    },
    //显示当前时间到分钟
    nowM: function () {
        return moment().format('YYYY-MM-DD HH:mm');
    },
    now: function () {
        return parseInt((new Date()).getTime() / 1000);
    },
    //格式化时间
    formatDate: function (t) {
        if (t > 0) {
            return moment(t * 1000).format('YYYY-MM-DD');
        } else {
            return "None";
        }
    },
    //格式化时间，时间戳为long型的
    formatDateLong: function (t) {
        return moment(t).format('YYYY-MM-DD');
    },
    formatPickDate: function (t) {
        return moment(t * 1000).format('YYYY-MM-DD HH:mm');
    },
    getTime: function (date) {
        var localTime = date.getTime();
        //var localOffset = date.getTimezoneOffset() * 60 * 1000;
        var localOffset = 0;
        return parseInt((localTime + localOffset) / 1000);
    },
    getTimeByStr: function (str) {
        var start = parseInt((new Date(str + " 00:00:00")).getTime() / 1000);
        var end = parseInt((new Date(str + " 23:59:59")).getTime() / 1000);
        return [start, end];
    },
    getStartTimeByStr: function (str) {
        return parseInt((new Date(str + " 00:00:00")).getTime() / 1000);
    },
    getEndTimeByStr: function (str) {
        return parseInt((new Date(str + " 23:59:59")).getTime() / 1000);
    },
    //获得指定日期的00:00:00-23:59:59
    getFullDay: function (t) {
        var n = this.formatDate(t);
        var start = parseInt((new Date(n + " 00:00:00")).getTime() / 1000);
        var end = parseInt((new Date(n + " 23:59:59")).getTime() / 1000);
        return [start, end];
    },
    //获得指定日期的00:00:00-23:59:59
    getFullDayLong: function (t) {
        var n = this.formatDate(t);
        var start = parseInt((new Date(n + " 00:00:00")).getTime());
        var end = parseInt((new Date(n + " 23:59:59")).getTime());
        return [start, end];
    },
    //获得今日00：00：00至23：59：59
    getTodayStartAndEndLong: function () {
        return this.getFullDayLong(this.now());
    },
    //获得今日00：00：00至23：59：59
    getTodayStartAndEnd: function () {
        return this.getFullDay(this.now());
    }
}