import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import RQ from '@/api/request.js'
import Func from "@/common/func.js"
import VueClipboard from 'vue-clipboard2'
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)
Vue.use(ElementUI)

Vue.config.productionTip = false
Vue.prototype.$rq = RQ
Vue.prototype.$func = Func
window.$vue=new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
