//后台
const backend = [
    //会员列表
    {
        path: '/backend/memberindex',
        name: 'BackendMemberIndex',
        component: (resolve) => require(['@/views/backend/member/memberindex.vue'], resolve)
    },
    //授权店铺列表
    {
        path: '/backend/shopsindex',
        name: 'BackendShopsIndex',
        component: (resolve) => require(['@/views/backend/member/shopsindex.vue'], resolve)
    },
    //会员购买记录
    {
        path: '/backend/memberbuyindex',
        name: 'BackendMemberBuyIndex',
        component: (resolve) => require(['@/views/backend/member/memberbuyindex.vue'], resolve)
    },
    //用户操作日志
    {
        path: '/backend/memberopindex',
        name: 'BackendMemberOpIndex',
        component: (resolve) => require(['@/views/backend/member/memberopindex.vue'], resolve)
    },
    //运营管理
    //套餐列表
    {
        path: '/backend/setmealindex',
        name: 'BackendSetMealIndex',
        component: (resolve) => require(['@/views/backend/operate/setmealindex.vue'], resolve)
    },
    //用户访问
    {
        path: '/backend/visiindex',
        name: 'BackendVisiIndex',
        component: (resolve) => require(['@/views/backend/operate/visiindex.vue'], resolve)
    },
    //用户访问明细
    {
        path: '/backend/visidetail',
        name: 'BackendVisiDetail',
        component: (resolve) => require(['@/views/backend/operate/visidetail.vue'], resolve)
    },
    //系统管理
    //系统模块
    {
        path: '/backend/systemmodule',
        name: 'BackendSystemModule',
        component: (resolve) => require(['@/views/backend/system/systemmodule.vue'], resolve)
    },
    //数据库
    {
        path: '/backend/databaseindex',
        name: 'BackendDatabaseIndex',
        component: (resolve) => require(['@/views/backend/system/databaseindex.vue'], resolve)
    },
    //语言包
    {
        path: '/backend/langindex',
        name: 'BackendLangIndex',
        component: (resolve) => require(['@/views/backend/system/langindex.vue'], resolve)
    },
    //配置
    {
        path: '/backend/configindex',
        name: 'BackendConfigIndex',
        component: (resolve) => require(['@/views/backend/system/configindex.vue'], resolve)
    },
    //系统监控
    //店铺监控
    {
        path: '/backend/shopmonitorindex',
        name: 'BackendShopMonitorIndex',
        component: (resolve) => require(['@/views/backend/monitor/shopmonitor.vue'], resolve)
    },
];
export default backend;