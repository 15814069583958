import Vue from 'vue'
import VueRouter from 'vue-router'
import Front from "./front/index"
import Backend from "./backend/index"
Vue.use(VueRouter)

let routes = [
]
routes = routes.concat(Front, Backend);
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
